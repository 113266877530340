import { createRouter, createWebHistory } from "vue-router";
import ClientView from "@/views/ClientView.vue";
import AdminView from "@/views/AdminView";
import useUserStore from "@/stores/user";

const routes = [
  {
    path: "/",
    name: "ClientView",
    component: ClientView,
    redirect: "/",
    children: [
      {
        path: "/",
        name: "HomeView",
        component: () => import("@/views/client/HomeView.vue"),
      },
      {
        path: "/certificates",
        name: "CertificateView",
        component: () => import("@/views/client/CertificateView.vue"),
      },
      {
        path: "/gallery",
        name: "PhotoGalleryView",
        component: () => import("@/views/client/PhotoGalleryView.vue"),
      },
      {
        path: "/contacts",
        name: "ContactsView",
        component: () => import("@/views/client/ContactsView.vue"),
      },
      {
        path: "/about",
        name: "AboutView",
        component: () => import("@/views/client/AboutView.vue"),
      },
      {
        path: "/digest",
        name: "DigestView",
        component: () => import("@/views/client/DigestView.vue"),
      },
      {
        path: "/news",
        name: "NewsView",
        component: () => import("@/views/client/NewsView.vue"),
      },
      {
        path: "/personal",
        name: "PersonalSupportView",
        component: () => import("@/views/client/PersonalSupportView.vue"),
      },
      {
        path: "/hackathons",
        name: "HackathonsView",
        component: () => import("@/views/client/HackathonsView.vue"),
      },
      {
        path: "/seminar",
        name: "SeminarTrainingsView",
        component: () => import("@/views/client/SeminarTrainingsView.vue"),
      },
    ],
  },
  {
    path: "/admin",
    name: "admin",
    meta: {
      requiresAuth: true,
    },
    redirect: "/admin/gallery",
    component: AdminView,
    children: [
      {
        path: "/admin/gallery",
        name: "gallery",
        component: () => import("@/views/admin/gallery/ListView.vue"),
      },
      {
        path: "/admin/gallery/:id",
        name: "gallery_add_edit",
        component: () => import("@/views/admin/gallery/AddEditView.vue"),
      },
      {
        path: "/admin/news",
        name: "news",
        component: () => import("@/views/admin/news/ListView.vue"),
      },
      {
        path: "/admin/news/:id",
        name: "news_add_edit",
        component: () => import("@/views/admin/news/AddEditView.vue"),
      },
      {
        path: "/admin/certificates",
        name: "certificates",
        component: () => import("@/views/admin/certificates/ListView.vue"),
      },
      {
        path: "/admin/certificates/:id",
        name: "certificates_add_edit",
        component: () => import("@/views/admin/certificates/AddEditView.vue"),
      },
      {
        path: "/admin/digest",
        name: "digest",
        component: () => import("@/views/admin/digest/ListView.vue"),
      },
      {
        path: "/admin/digest/:id",
        name: "digest_add_edit",
        component: () => import("@/views/admin/digest/AddEditView.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/home",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkExactActiveClass: "active-text-menu",
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (!to.meta.requiresAuth) {
    next();
    return;
  }
  const store = useUserStore();
  if (store.userLoggedIn) {
    next();
  } else {
    next({
      name: "login",
    });
  }
});

export default router;
