<template>
  <div class="header-wrapper">
    <div class="header container">
      <div class="left-side">
        <div class="logo place-center pointer" @click="$router.push({ name: 'HomeView' })">
          <img src="@/assets/icons/logo.svg" alt="" />
        </div>
        <set-language-box></set-language-box>
      </div>
      <div class="right-side">
        <div class="menu" v-if="$root.laptop">
          <router-link class="item" :to="{ name: 'HomeView' }">{{ $t("l_Main") }}</router-link>
          <router-link class="item" :to="{ name: 'AboutView' }">{{ $t("l_About_us") }}</router-link>
          <router-link class="item" :to="{ name: 'NewsView' }">{{ $t("l_News") }}</router-link>
          <router-link class="item" :to="{ name: 'CertificateView' }">{{ $t("l_Certificate") }}</router-link>
          <router-link class="item" :to="{ name: 'PhotoGalleryView' }">{{ $t("l_Gallery") }}</router-link>
          <router-link class="item" :to="{ name: 'DigestView' }">{{ $t("l_Digest") }}</router-link>
          <router-link class="item" :to="{ name: 'ContactsView' }">{{ $t("l_Contacts") }}</router-link>
        </div>
        <div class="burger place-center" v-else>
          <a-dropdown placement="bottomRight">
            <img src="@/assets/imgs/hamburger.svg" alt="" />
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <router-link class="item" :to="{ name: 'HomeView' }">{{ $t("l_Main") }}</router-link>
                </a-menu-item>
                <a-menu-item>
                  <router-link class="item" :to="{ name: 'AboutView' }">{{ $t("l_About_us") }}</router-link>
                </a-menu-item>
                <a-menu-item>
                  <router-link class="item" :to="{ name: 'NewsView' }">{{ $t("l_News") }}</router-link>
                </a-menu-item>
                <a-menu-item>
                  <router-link class="item" :to="{ name: 'CertificateView' }">{{ $t("l_Certificate") }}</router-link>
                </a-menu-item>
                <a-menu-item>
                  <router-link class="item" :to="{ name: 'PhotoGalleryView' }">{{ $t("l_Gallery") }}</router-link>
                </a-menu-item>
                <a-menu-item>
                  <router-link class="item" :to="{ name: 'DigestView' }">{{ $t("l_Digest") }}</router-link>
                </a-menu-item>
                <a-menu-item>
                  <router-link class="item" :to="{ name: 'ContactsView' }">{{ $t("l_Contacts") }}</router-link>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SetLanguageBox from "@/components/SetLanguageBox.vue";
export default {
  components: {
    SetLanguageBox,
  },
};
</script>

<style lang="scss" scoped>
$laptop: 1114px;
$tablet: 769px;

.header-wrapper {
  position: sticky;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  height: 84px;
  display: flex;
  align-items: center;
  background-color: white;
  .header {
    width: 100%;
    margin-inline: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-side {
      display: flex;
      align-items: center;
      .logo {
        margin-right: 42px;
        @media (max-width: $laptop) {
          margin-right: 18px;
        }
      }
    }
    .right-side {
      .burger {
        border-radius: 8px;
        background: #0a3263;
        width: 38px;
        height: 35px;
      }
      .menu {
        display: flex;
        gap: 30px;
        @media (max-width: $laptop) {
          gap: 15px;
        }
        .item {
          color: #18191f;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          @media (max-width: $laptop) {
            font-size: 15px;
          }
        }
        .item-active {
          color: #0a3263;
        }
      }
    }
  }
}
</style>
