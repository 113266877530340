import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router/index.js";
import { createPinia } from "pinia";
import "@/assets/styles/global.css";
import "@/assets/styles/reset.css";
import Antd from "ant-design-vue";
import i18n from "@/locales/index.js";

const app = createApp(App);
app.config.globalProperties.$timeFormat = timeFormat;

app.use(router).use(i18n).use(createPinia()).use(Antd).mount("#app");

function timeFormat(t, hm, s) {
  if (!t) {
    return "";
  }
  if (typeof t != "number") {
    return t;
  }
  if (s) {
    t = t / 1000;
  }
  let d = new Date(t * 1000);
  let day = d.getDate();
  let m = d.getMonth() + 1;
  let y = d.getFullYear();
  if (day.toString().length == 1) {
    day = "0" + day.toString();
  }
  if (m.toString().length == 1) {
    m = "0" + m.toString();
  }
  let h = d.getHours();
  let mn = d.getMinutes();
  if (h.toString().length < 2) {
    h = "0" + h;
  }
  if (mn.toString().length < 2) {
    mn = "0" + mn;
  }
  if (hm) {
    return `${day}/${m}/${y} ${h}:${mn}`;
  }
  return `${day}.${m}.${y}`;
}
