export default {
  l_News: "Новости",
  l_More_details: "Подробнее",
  l_Consulting_services: "Консалтинговые услуги",
  l_Training_and_development: "Обучение и развитие",
  l_Consulting_services_text: "Если у вас есть вопросы о формате или вы не знаете, что выбрать, оставьте свой номер - мы позвоним, чтобы ответить на все ваши вопросы.",
  l_Send: "Отправить",
  l_Personnel_support: "Кадровая поддержка",
  l_Read_more: "Читать далее",
  l_Seminars_and_trainings: "Семинары и тренинги",
  l_Corporate_hackathons: "Корпоративные хакатоны",
  l_About_the_center_text: "Программы курсов повышения квалификации являются формой дополнительного профессионального образования для руководителей высшего и среднего звена и для специалистов в сфере нефтегазодобывающего комплекса.",
  l_About_the_center: "О центре",
  l_Learn_more: "Узнать больше",
  l_years: "лет",
  l_Practice: "Практики",
  l_Teachers: "Преподавателей",
  l_Regular_customers_in_the_face_of_the_company: "Постоянных клиентов в лице компании",
  l_Success: "Успех",
  l_Our_business_trainers: "Наши бизнес-тренера",
  l_Our_business_trainers_text: "На вас работают опытные специалисты с высокой квалификацией, которые являются экспертами в разных областях экономики, финансов, права.",
  l_Consultation: "Консультация",
  l_We_are_trusted: "Нам доверяют",
  l_We_are_trusted_text: "Мы ценим наших клиентов и всегда ориентиуемся на долгосрочное сотрудничество",
  l_Why_are_we: "Почему мы?",
  l_Why_are_we_text: "Главные ценности нашей компании – стабильность, надежность и уверенность в завтрашнем дне!",
  l_Offline_and_online_learning_format: "Офлайн и онлайн формат обучения",
  l_Individual_approach_to_each_customer: "Индивидуальный подход к каждому Заказчику",
  l_Organizing_Corporate_hackathons: "Организация корпоративных хакатонов",
  l_Many_years_of_successful_experience_of_the_team_in_the_market_of_educational_services: "Многолетний успешный опыт работы коллектива на рынке образовательных услуг",
  l_Organization_of_corporate_trainings_on_the_territory_of_the_Customer_and_with_travel_abroad: "Организация корпоративных тренингов на территории Заказчика и с выездом зарубеж",
  l_Full_HR_support_and_legal_expertise: "Полное кадровое сопровождение и юридическая экспертиза",
  l_Certificates: "Сертификаты",
  l_Certificates_text: "Наше образовательное учреждение соответствует высоким стандартам качества и предлагает учащимся качественные  тренинги, семинары и многие другие обучающие курсы повышения квалификации.",
  l_Main: "Главная",
  l_About_us: "О нас",
  l_Certificate: "Сертификат",
  l_Gallery: "Галерея",
  l_Digest: "Дайджест",
  l_Contacts: "Контакты",
  l_We_are_in_social_media: "Мы в соц. сетях",
  l_Address: "Адрес",
  l_Email: "Эл. почта",
  l_Need_a_consultation: "Нужна консультация?",
  l_Need_a_consultation_text: "Если у вас есть какие-либо вопросы об обучении и вакансии, пожалуйста, заполните форму ниже и отправьте нам",
  l_Enter_phone_number: "Введите номер телефон",
  l_Corporate_hackathons_text: "Это отличная возможность для развития профессиональных навыков, обмена опытом и создания уникальных проектов. Добро пожаловать в мир хакатонов!",
  l_At_hackathons: "На хакатонах мы призываем участников преодолеть традиционные рамки и проявить свою креативность и инновационный подход.",
  l_Teams_have: "Команды имеют возможность выбирать между различными темами и вызовами, которые они должны решить вместе. Они могут быть связаны с технологиями, социальными проблемами, экологией, здравоохранением и другими областями.",
  l_During_the_hackathon:
    "В течение хакатона командам предоставляются ресурсы и поддержка со стороны экспертов и менторов. Участники имеют возможность обмениваться опытом, учиться друг у друга и создавать свои проекты, которые могут привести к новым технологическим решениям, продуктам или услугам.",
  l_One_of: "Одним из главных преимуществ хакатонов является создание сети контактов и возможность показать свои навыки перед экспертами и потенциальными работодателями.",
  l_Prizes_and: "Призы и награды также могут быть предоставлены лучшим командам, поощряя их усилия и инновационные идеи.",
  l_We_invite: "Мы приглашаем всех, кто интересуется технологиями, инновациями и решением сложных задач, принять участие в наших хакатонах. ",
  l_Business_trainers: "Бизнес-тренеры",
  l_About_us_text: "Учебный центр «RCDR Trainings» ориентирована на развитие различных видов компетенций, таких как профессиональные, управленческие, коммуникативные, лидерские, творческие с использованием последних инновационных подходов, включая онлайн или офлайн форматы обучения.",
  l_Advanced_training: "Программы курсов повышения квалификации являются формой дополнительного профессионального образования для руководителей высшего и среднего звена и для специалистов в сфере нефтегазодобывающего комплекса.",
  l_The_level:
    "Уровень обучения в Центре соответствует мировым стандартам. Тематика курсов согласована с ведущими специалистами нефтегазовых компаний. Разработчиками учебных программ являются высококвалифицированные специалисты с большим производственным и педагогическим стажем, в том числе и иностранные, а также ведущие специалисты научно-исследовательских институтов, привлекаемые к учебному процессу в Центре.",
  l_The_center:
    "Центр располагает всем необходимым для повышения качества обучения: аудиториями, наглядными пособиями для практических и теоретических занятий, программным обеспечением, учебными пособиями, систематическими коллекциями образцов, шлифов, минералов, горных пород и необходимыми справочными материалами.",
  l_The_center_offers: "Своим клиентам центр предоставляет широкий выбор курсов. Среди приоритетных направлений являются следующие",
  l_Oil_and_gas: "Нефтегазовая геология и геофизика.",
  l_Subsoil_use: "Недропользование",
  l_Ecology_of_oil_and_gas_production: "Экология нефтегазового производства",
  l_Oil_and_gas_geology_and_geophysics: "Нефтегазовая геология и геофизика",
  l_Modern_state_of: "Современные состояние минерально-сырьевой базы Казахстана",
  l_Modern_methods_of_forecasting: "Современные методы прогнозирования полезных ископаемых в Казахстане",
  l_Modern_methods_of_drilling: "Современные методы бурения, добычи и разработки нефтегазовых месторождений",
  l_Purpose_of_the_center: "Цель Центра",
  l_Purpose_of_the_center_text:
    "Центр повышения квалификации и переподготовки кадров создан на базе с учетом потребности рынка труда, разработала ряд программ профессиональной подготовки и повышения квалификации по современным геолого-геофизическим методам поисков углеводородного сырья и других видов полезных ископаемых, экологии нефтегазового производства, а также бурения, разработки и добычи углеводородного сырья и т.д...",
  l_Before_the_start_of_classes_it_is_necessary: "До начала занятий необходимо",
  l_The_cost_of_individual: "Стоимость индивидуального обучения определяется из общего объема учебной нагрузки, включающей необходимое количество лекционных и практических занятий, а также использования программного обеспечения и аппаратуры.",
  l_Apply_for_training: "Подать заявку на обучение, отправив ее по е-mail. При подаче заявки просим также сообщить все сведения, необходимые для оформления Счета-фактуры (ИИК, БИН, Юридический адрес предприятия, наименование и почтовый адрес грузополучателя).",
  l_To_pay_for_participation: "Оплатить участие курсов по счету на предварительную оплату, который выписывается и отсылается на предприятие в течение трех рабочих дней после получения заявки.",
  l_If_necessary_training_contract: "При необходимости договор на обучение может быть заключен заранее и направлен в адрес предприятия.",
  l_Pay_for_participation: "Оплатить участие курсов по счет фактуре , который выписывается и отсылается на предприятие в течение трех рабочих дней после окончания обучения",
  l_Photo_gallery: "Фотогалерея",
  l_Your_name: "Ваше имя",
  l_Phone_number: "Номер телефона",
  l_Wishes: "Пожелания",
  l_Write_your_wishes: "Напишите ваши пожелания *",
  l_I_agree: "Я согласен(на) на обработку персональных данных",
  l_Working_time: "Рабочее время",
  l_Social_media: "Соцсети",
  l_About_us_title: "Основными уставными направлениями деятельности Учебного центра являются",
  l_What_will_you_get: "Что вы получите?",
  l_Many_years_of_experience: "Многолетний опыт",
  l_Many_years_of_experience_text:
    "Многолетний опыт проведения семинаров по кадровому делопроизводству, позволяет нам в своей работе использовать не только собственный опыт, но также опираться и на опыт применения действующего трудового законодательства нашими слушателями в различных регионах нашей страны;",
  l_No_risks: "Нет рисков",
  l_No_risks_text: "Наша команда оказывает комплексную всестороннюю помощь предпринимателям, которая позволяет избежать возможных негативных рисков при ведении кадрового делопроизводства.",
  l_Saving_time: "Экономия времени",
  l_Saving_time_text: "Наши сотрудники в короткие сроки разработают и внедрят эффективную систему кадрового производства с учетом особенностей и специфики вашего бизнеса.",
  l_Solutions_in_field_of: "Решения в области аутсорсинга и управления персоналом.",
  l_Legal_and_personnel: "Юридическая и кадровая экспертиза документов компании",
  l_Order_kp: "Заказать КП",
  l_If_you_have: "Если у вас",
  l_Order_call: "заказать звонок",
  l_Order: "Заказать",
  l_Manager: "Менеджер",
  l_Do_not_have_your_own_HR: "Нет своего кадровика?",
  l_It_is_planned_to_check: "Планируется проверка инспектора по труду",
  l_Not_sure_if_you_comply: "Не уверены, что соблюдаете трудовое законодательство",
  l_Your_staff_is_less_than: "Ваш штат сотрудников менее 50 человек",
  l_HR_work_is_conducted: "Кадровую работу ведет бухгалтер или другое лицо",
  l_There_is_a_risk_of_employees: "Есть риск обращения работников в суд",
  l_Personnel_selection_is_necessary: "Необходим подбор персонала",
  l_Restore_office_work: "Восстановить делопроизводство",
  l_Calculate_bonuses: "Рассчитать ЗП и бонусы",
  l_Consulting_sub_title: "Комплексная защита и эффективное развитие Вашего бизнеса!",
  l_In_order_organize_additional: "С целью организации дополнительного образования и проведения курсов повышения квалификации для специалистов любого уровня нефтегазовых и финансовых секторов.",
  l_The_courses_training_center: "Курсы Учебного Центра– это дополнительные и качественные знания в удобном для Вас формате обучения. Тренинги проводят лучшие преподаватели и эксперты, интегрирующие практический опыт и теоретический потенциал.",
  l_Training_center_conducts_adapts: "Учебный Центр проводит и адаптирует тренинги под Ваши потребности с учетом стратегий развития и текущих задач Вашей компании по следующим направлениям",
  l_Direction: "Направление",
  l_Oil_and_gas_engineering: "Нефтегазовая инженерия",
  l_Oil_and_gas_geology: "Нефтегазовая геология",
  l_Legalization_republic_kazakhstan: "Законодательство РК в нефтегазовой отрасли",
  l_Project_management: "Управление проектами",
  l_Finance_taxation: "Финансы и налогооблажение",
  l_Accounting_oil_gas_industry: "Учет в нефтегазовой отрасли",
  l_Assessment_oil_gas_industry: "Оценка в нефтегазовой промышленности",
  l_Sales_negotiations_communications: "Продажи, переговоры, коммуникации",
  l_Team_build_HR: "Team Building и HR",
  l_Marketing_PR: "Маркетинг и PR",
  l_Environmental_protection: "Охрана окружающей среды",
  l_Place_of_training: "Место проведения обучения",
  l_Location_Company: "Место локации Компании",
  l_Customer_territory: "Территория заказчика",
  l_Field_courses_abroad_Customer: "Выездные курсы заграницей (По согласованию с Заказчиком)",
  l_Training_format: "Формат обучения",
  l_Open_training_formation: "Открытый тренинг (формирование группы от 8 участников из разных компаний)",
  l_Corporate_training_formation: "Корпоративный тренинг (формирование группы до 20 человек из одной компании)",
  l_The_cost_training_varies_depending: "Стоимость обучения варьируется в зависимости от",
  l_Duration_training: "Продолжительности обучения",
  l_Venue_format_event: "Места и формата проведения",
  l_Number_participants_group: "Количества участников в группе",
  l_About_us_title1: "реализация дополнительных профессиональных программ (программы",
  l_About_us_title2: "повышения квалификации, программы профессиональной переподготовки);",
  l_About_us_title3: "организация и проведение повышение квалификации и",
  l_About_us_title4: "профессиональной переподготовки работников, руководителей и специалистов",
  l_About_us_title5: "организаций по дополнительным профессиональным программам повышения квалификации, программам профессиональной переподготовки",
  l_About_us_title6: "повышение качества образовательных услуг по повышению квалификации и переподготовке работников.",
  l_Answers_to_our_frequently_asked_questions: "Ответы на наши часто задаваемые вопросы",
  l_Course_structure: "Структура курса",
  l_Form_of_stufy: "Форма обучения",
  l_fill_time_block_corporate_training: "очная, блоковая, корпоративное обучение",
  l_Teaching_methodology: "Методика обучения",
  l_Teaching_methodology1: "для обеспечения максимальной эффективности обучение проводится в виде тренинга: лекции, презентации, практические занятия.",
  l_course_structure1: "Предусматривается активное участие слушателей в виде",
  l_course_structure2: "обсуждения проблемных вопросов в группе;",
  l_course_structure3: "выполнения практических заданий;",
  l_course_structure4: "изучения систематических коллекций шлифов и образцов горных пород;",
  l_course_structure5: "ознакомления с современным программным обеспечением.",
  l_Duration_of_study: "Продолжительность обучения",
  l_from_2_days_to_2_week: "от 2 дней до 2 недель",
  l_Reporting_Form: "Форма отчетности",
  l_Reporting_Form1: "итоговое тестирование на закрепление знании, экзаменация",
  l_Final_document: "Итоговой документ",
  l_Final_document1: "сертификат о прохождении курса повышения квалификации",
  l_FInal_document_header: "Итоговый документ: Свидительство об оканчании курсов.",
  l_Final_document_text1: "Обучение по индивидуальным программам и по желанию клиентов",
  l_Final_document_text2: "Программа может быть изменена и адаптирована к запросам слушателей!",
  l_Final_document_text3: "Учитывая пожелания наших клиентов, Центр осуществляет работу по индивидуальным договорам и заявкам предприятий",
  l_Course_topics_may_be_adjusted_to_reflect_following: "Тематика курсов может быть скорректирована с учетом следующего",
  l_production_needs_and_customer_issues: "производственной необходимости и проблемам заказчика,",
  l_basic_education: "базовым образованием,",
  l_required_deadlines: "необходимыми сроками,",
  l_form_of_learning: "формой обучения",
  l_For_each_category_of_listeners_possible: "Для каждой категории слушателей возможно",
  l_For_each_category_text_indi: "составление индивидуальных учебно-тематических планов занятий,",
  l_For_each_category_text_program: "программы могут носить как обзорный характер, охватывая минимум необходимых вопросов, так и быть узконаправленными,",
  l_For_each_category_text_face: "с частными лицами работа осуществляется по личному заявлению,",
  l_For_each_category_text_cource: "отдельные курсы могут читаться на английском языке.",
  l_For_each_category_text_time: "согласовывается время, место и продолжительность проведения обучения.",
  l_Conditions_of_education: "Условия обучения",
  l_Conditions_of_education_title: "Центр проводит подготовку и повышение квалификации для специалистов, имеющих высшее или среднее профессиональное образование.",
  l_Consitions_of_education_price: "В стоимость обучения на курсах повышения квалификации входит:",
  l_trainin_of_listeners: "обучение слушателей;",
  l_handout_material: "раздаточный материал;",
  l_coffee_breaks_during_breaks: "кофе-брейк в перерывах.",
  l_Discussion_of_the_fixed_material: "Обсуждение закрепленного материала",
  l_Onsite_training: "Обучение с выездом на предприятия",
  l_Opsite_training_text:
    "По желанию слушателей наши специалисты выезжают на предприятие заказчика для обучения по программам Центра. В этом случае могут быть составлены индивидуальные учебно-тематические планы занятий. Слушатели также обеспечиваются раздаточным материалом и необходимой справочной и методической литературой.",
  l_Open_training_price: "Стоимость обучения с выездом на места определяется исходя из количества часов запланированной учебной нагрузки, количества слушателей, а также командировочных расходов преподавателей.",
  l_Conditions_for_the_audience: "Условия к аудиториям, если курс проходит на базе заказчика",
  l_Conditions_for_listener: "количество слушателей в группе: 8 – 15 сотрудников.",
  l_Consitions_for_pk: "аудитория, оборудованная проектором и ноутбуком для демонстрации презентаций;",
  l_Consitions_for_wall_board: "доска настенная для записей (маркеры или мел);",
  l_Privacy_policy: "Политика конфиденциальности",
  l_Please_enter_your_name: "Пожалуйста, введите ваше имя!",
  l_Please_enter_your_phone_number: "Пожалуйста, введите свой номер телефона!",
  l_Please_check: "Пожалуйста, нажмите!",
  l_Successfully_sent: "Ваш запрос был принят. Мы свяжемся с Вами в ближайшее время.",
  l_Consulting_info_text:
    " Уровень обучения в Центре соответствует мировым стандартам. Тематика курсов согласована с ведущими специалистами нефтегазовых компаний. Разработчиками учебных программ являются высококвалифицированные 1 специалисты с большим производственным и педагогическим стажем, в том числе и иностранные, а также ведущие специалисты научно-исследовательских институтов, привлекаемые к учебному процессу в Центре.",
  l_Consulting_info_text_part2: "Программы курсов повышения квалификации являются формой дополнительного профессионального образования для руководителей высшего и среднего звена и для специалистов в сфере нефтегазодобывающего комплекса....",
  l_Consulting_info_text_part3:
    "Уровень обучения в Центре соответствует мировым стандартам. Тематика курсов согласована с ведущими специалистами нефтегазовых компаний. Разработчиками учебных программ являются высококвалифицированные специалисты с большим производственным и педагогическим стажем, в том числе и иностранные, а также ведущие специалисты научно-исследовательских институтов, привлекаемые к учебному процессу в Центре. Программы курсов повышения квалификации являются формой дополнительного профессионального образования для руководителей высшего и среднего звена и для специалистов в сфере нефтегазодобывающего комплекса.",
  l_Social_medias: "Социальные сети",
  l_Leave_request: "Оставьте запрос, мы вам перезвоним",
};
