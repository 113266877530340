<template>
  <div class="menu-box-area">
    <div class="logo">
      <img class="pointer" @click="$router.push({ name: 'HomeView' })" src="@/assets/icons/white-logo.svg" alt="" />
    </div>
    <div class="menus">
      <div class="menus-item" v-for="(item, index) in menuList" :key="index">
        <div class="row" :class="{ active: routerName == item.routerName }" @click="onSetMenu(item.routerName)">
          <span class="icon material-symbols-outlined">
            {{ item.icon }}
          </span>
          <span class="text">{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        {
          name: "Сертификаты",
          routerName: "certificates",
          icon: "card_membership",
        },
        {
          name: "Галерия",
          routerName: "gallery",
          icon: "gallery_thumbnail",
        },
        {
          name: "Новости",
          routerName: "news",
          icon: "news",
        },
        {
          name: "Дайджест",
          routerName: "digest",
          icon: "grade",
        },
      ],
    };
  },
  computed: {
    routerName() {
      return this.$route.name;
    },
  },
  methods: {
    onSetMenu(e) {
      this.$router.push({
        name: e,
      });
      this.$emit("select", 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-box-area {
  .logo {
    padding: 20px;
    border-bottom: 1px solid white;
  }
  .menus {
    margin: 10px 0;
    overflow: hidden;

    .menus-item {
      // height: 50px;
      display: flex;
      flex-direction: column;

      .row {
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: center;
        color: #f1f1f1;
        transition: all 0.2s;
        white-space: nowrap;

        &:hover {
          transform: translate(5px);
        }

        .icon-right {
          transition: all 0.2s;

          &.show {
            transform: rotate(90deg);
          }
        }
      }

      .icon {
        height: 100%;
        font-size: 20px;
        cursor: pointer;
        margin: auto 0;
      }

      .text {
        height: 100%;
        margin-left: 8px;
        cursor: pointer;
        white-space: ellipsis;
      }

      &.collapsible {
        justify-content: center;

        .icon {
          font-size: 25px;
        }
      }

      .active {
        color: #fff;
        background-color: #1890ff;
        // transform: translate(5px);
        display: flex;
        align-items: center;

        .text {
          // transform: scale(1.1);
        }
      }
    }
  }
}
</style>
