<template>
  <footer class="footer">
    <div class="footer-info">
      <div class="container">
        <div class="mobile-icon pointer"><img src="@/assets/icons/white-logo.svg" width="100 " height="34" alt="white logo" @click="$router.push({ name: 'HomeView' })" /></div>
        <div class="footer-info-wrapper">
          <div class="footer-info-logo pointer">
            <img src="@/assets/icons/white-logo.svg" width="100" height="34" alt="white logo" @click="$router.push({ name: 'HomeView' })" />
          </div>
          <div class="social-block">
            <span class="social-title">{{ $t("l_We_are_in_social_media") }}:</span>
            <div class="social-icons align-center">
              <a href="https://www.facebook.com/rcdrkz?mibextid=LQQJ4d" target="_blank">
                <img class="pointer" src="@/assets/icons/facebook.svg" alt="facebook" />
              </a>
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7084403696301699073?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7084403696301699073%29" target="_blank">
                <img class="pointer" src="@/assets/icons/linkedin.svg" alt="linkedin" />
              </a>
              <a href="https://instagram.com/rcdreducation?igshid=MzRlODBiNWFlZA==" target="_blank">
                <img class="pointer" src="@/assets/icons/instagram.svg" alt="instagram" />
              </a>
            </div>
          </div>
          <div class="footer-info-phone">
            <p class="footer-title">{{ $t("l_Contacts") }}</p>
            <div class="contacts">
              <p class="contact-phone">+7 705 328 69 28</p>
              <p class="contact-phone">+7 747 093 90 48</p>
              <p class="contact-phone">+7 747 093 90 47</p>
            </div>
          </div>
          <div class="footer-info-addres">
            <p class="footer-title">{{ $t("l_Address") }}</p>
            <div class="contacts">
              <p class="addres">г.Алматы, Бостандыкский район, ул. Тимирязева, 15Б, 3 этаж, офисы 2-6</p>
            </div>
          </div>
          <div class="footer-info-email">
            <p class="footer-title">{{ $t("l_Email") }}:</p>
            <!-- <p class="email">info@rcdr.kz</p> -->
            <a class="email" href="mailto:info@rcdr.kz">info@rcdr.kz</a>
          </div>
          <div class="footer-info-sendEmail">
            <p class="footer-title">{{ $t("l_Leave_request") }}</p>
            <div class="contacts">
              <p class="descr">
                {{ $t("l_Need_a_consultation_text") }}
                <a class="email" href="mailto:rcdr@rcdr.kz">rcdr@rcdr.kz</a>
              </p>
            </div>
            <div class="input-phone">
              <div>
                <input v-maska data-maska="+7 (###) ### ## ##" type="text" v-model="phoneNumber" :placeholder="$t('l_Enter_phone_number')" class="input" name="phone" id="phone" />
                <button type="submit" class="btn-submit pointer" @click.prevent="showModal">{{ $t("l_Send") }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright">Copyright © {{ new Date().getFullYear() }} Kymbat Tursyntay</div>
    </div>
  </footer>
  <a-modal destroyOnClose wrap-class-name="modal-content" width="90%" style="max-width: 391px" :maskClosable="true" v-model:open="open" :centered="true" :footer="null">
    <ContactForm :isModal="true" :isPersonal="{ phoneNumber: phoneNumber }" />
  </a-modal>
</template>

<script>
import { vMaska } from "maska";
import ContactForm from "@/components/ContactForm.vue";

export default {
  directives: { maska: vMaska },
  components: {
    ContactForm,
  },
  data() {
    return {
      phoneNumber: null,
      open: false,
    };
  },
  methods: {
    showModal() {
      this.open = true;
    },
  },
};
</script>

<style lang="scss" scoped>
$laptopL: 1276px;
$laptop: 1024px;

.footer {
  overflow-x: hidden;

  .footer-info {
    background-color: #0a3263;
    padding: 60px 0 28px 0;
    color: #fff;

    @media (max-width: $laptop) {
      padding: 21px 0 17px 0;
    }

    .mobile-icon {
      display: none;

      @media (max-width: $laptop) {
        display: block;
        margin-bottom: 20px;
      }
    }

    .footer-info-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr) 204px 362px;
      grid-template-rows: repeat(2, 1fr);
      column-gap: 113px;

      @media (max-width: $laptopL) {
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(2, 1fr);
      }

      .footer-title {
        font-size: 18px;
        font-weight: 700;

        @media (max-width: $laptop) {
          color: #98a2b3;
          font-size: 16px;
          font-weight: 700;
          line-height: 16px;
          text-transform: capitalize;
        }
      }

      .footer-info-sendEmail {
        grid-row: 1 / 3;
        grid-column-end: 5;

        @media (max-width: $laptopL) {
          grid-row: 3 / 4;
          grid-column: 1 / 3;
          margin-top: -24px;
        }

        .input-phone {
          position: relative;
          margin-top: 24px;

          .input {
            border: none;
            padding: 21px 0 21px 26px;
            border-radius: 45.595px;
            border: 2px solid #f1f1f1;
            background: #fff;
            width: calc(100% - 30px);
            outline: none;

            &::placeholder {
              color: #959595;
              font-size: 12.159px;
            }
          }
          .btn-submit {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            padding: 15.2px 30.4px;
            border-radius: 45.595px;
            background: #2639ed;
            color: #fff;
            font-size: 12px;
            border: none;
          }
        }

        .descr {
          @media (max-width: $laptop) {
            margin-top: 11px;
          }
        }
      }

      .footer-info-logo {
        @media (max-width: $laptopL) {
          display: none;
        }
      }

      .footer-info-email {
        grid-row: 2 / 3;
        grid-column: 3 /4;
        margin-top: 22px;

        @media (max-width: $laptopL) {
          margin-top: 0;
          grid-column: 1;
          grid-row: 1;
        }

        .email {
          margin-top: 10px;
        }
      }

      .footer-info-addres {
        @media (max-width: $laptopL) {
          grid-row: 2;
          grid-column: 1 / 2;
          margin-top: -40px;
        }
      }

      .footer-info-phone {
        @media (max-width: $laptopL) {
          grid-row: 2;
          margin-top: -40px;
        }
      }

      .contacts {
        margin-top: 24px;

        @media (max-width: $laptopL) {
          margin-top: 5px;
        }

        .addres {
          color: #fff;
          font-size: 14px;
          font-weight: 500;
          line-height: 200%;
          @media (max-width: $laptop) {
            font-size: 12px;
          }
        }
        .contact-phone {
          margin-bottom: 15px;
          color: #fff;
          font-size: 14px;
          font-weight: 500;
          line-height: 200%;

          @media (max-width: $laptop) {
            font-size: 12px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .social-block {
        margin-top: -50px;
        grid-column: 1 / 2;
        grid-row: 2 / 3;

        @media (max-width: $laptopL) {
          margin-top: 0;
          grid-row: 1;
          grid-column-start: 2;
        }

        .social-title {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;

          @media (max-width: $laptopL) {
            color: #98a2b3;
            font-size: 16px;
            font-weight: 700;
            line-height: 16px;
          }
        }
        .social-icons {
          margin-top: 15px;
          gap: 12px;

          @media (max-width: $laptopL) {
            margin-top: 7px;
          }
        }
      }
    }
    .copyright {
      width: 100%;
      text-align: center;
      margin-top: 53px;
      padding-top: 15px;
      border-top: 1px solid #f4f4f4;
      color: #a4a4a4;
      font-size: 14px;
    }
  }
}
</style>
