import { defineStore } from "pinia";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";

import { auth } from "@/includes/firebase";

let localeUserInfo = localStorage.getItem("userInfo") || null;
if (localeUserInfo) {
  localeUserInfo = JSON.parse(localeUserInfo);
}

export default defineStore("user", {
  state: () => ({
    userInfo: localeUserInfo || {},
  }),
  getters: {
    userLoggedIn(state) {
      if (typeof state.userInfo === "object" && Object.keys(state.userInfo).length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  actions: {
    async authenticate(values) {
      await signInWithEmailAndPassword(auth, values.email, values.password).then((res) => {
        this.userInfo = res.user;
        localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
      });
    },
    async signOut() {
      await signOut(auth);
      this.userInfo = {};
      localStorage.setItem("userInfo", JSON.stringify({}));
    },
  },
});
