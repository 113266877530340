import { createI18n } from "vue-i18n";

import kz from "@/locales/language/kz.js";
import rus from "@/locales/language/rus.js";
import en from "@/locales/language/en.js";

let currentLang = localStorage.getItem("currentLang") || "kz";
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  global: true,
  locale: currentLang,
  fallbackLocale: currentLang,
  warnHtmlMessage: false,
  messages: {
    kz,
    rus,
    en,
  },
});

export default i18n;
