export default {
  l_News: "Жаңалықтар",
  l_More_details: "Толығырақ",
  l_Consulting_services: "Консалтингтік қызметтер",
  l_Training_and_development: "Оқыту және дамыту",
  l_Consulting_services_text: "Сұрақтарыңыз болса немесе не таңдау керектігін білмесеңіз, нөміріңізді қалдырыңыз - біз барлық сұрақтарыңызға жауап беру үшін қоңырау шаламыз.",
  l_Send: "Жіберу",
  l_Personnel_support: "Персоналға қолдау көрсету",
  l_Read_more: "Толығырақ оқу",
  l_Seminars_and_trainings: "Семинарлар мен тренингтер",
  l_Corporate_hackathons: "Корпоративтік хакатондар",
  l_About_the_center: "Орталық туралы",
  l_About_the_center_text: "Біліктілікті арттыру курстарының бағдарламалары мұнай-газ саласының жоғары және орта буын басшыларына және мамандарына арналған қосымша кәсіптік білім беру нысаны болып табылады.",
  l_Learn_more: "Толығырақ",
  l_years: "жыл",
  l_Practice: "Тәжірибе",
  l_Teachers: "Мұғалімдер",
  l_Regular_customers_in_the_face_of_the_company: "Компаниялар біздің тұрақты тұтынушыларымыз",
  l_Success: "жетістік",
  l_Our_business_trainers: "Бизнес-тренерлер",
  l_Our_business_trainers_text: "Сіздер үшін экономиканың, қаржының, заңның әртүрлі салаларындағы сарапшы болып табылатын тәжірибелі және жоғары білікті мамандар жұмыс істейді.",
  l_Consultation: "Кеңес",
  l_We_are_trusted: "Бізге сенім артады",
  l_We_are_trusted_text: "Біз өз тұтынушыларымызды бағалаймыз және әрқашан ұзақ мерзімді ынтымақтастыққа назар аударамыз",
  l_Why_are_we: "Неліктен біз?",
  l_Why_are_we_text: "Біздің компаниямыздың басты құндылықтары тұрақтылық, болашаққа деген сенімділік!",
  l_Offline_and_online_learning_format: "Офлайн және онлайн оқу форматы",
  l_Individual_approach_to_each_customer: "Әрбір тұтынушыға жеке тәсіл",
  l_Organizing_Corporate_hackathons: "Корпоративтік хакатондарды ұйымдастыру",
  l_Many_years_of_successful_experience_of_the_team_in_the_market_of_educational_services: "Білім беру қызметтері нарығындағы көп жылдық тәжірибелі команда",
  l_Organization_of_corporate_trainings_on_the_territory_of_the_Customer_and_with_travel_abroad: "Тапсырыс берушінің аумағында және шетелге саяхаттаумен бірге корпоративтік тренингтерді ұйымдастыру",
  l_Full_HR_support_and_legal_expertise: "Толық HR қолдауы және заңгерлік сараптама",
  l_Certificates: "Сертификаттар",
  l_Certificates_text: "Біздің оқу орнымыз жоғары сапа стандарттарына сәйкес келеді және тыңдаушыларымызға сапалы тренингтер, семинарлар және көптеген басқа үздіксіз білім беру курстарын ұсынады.",
  l_Main: "Басты бет",
  l_About_us: "Біз туралы",
  l_Certificate: "Сертификат",
  l_Gallery: "Галерея",
  l_Digest: "Дайджест",
  l_Contacts: "Байланыстар",
  l_We_are_in_social_media: "Біз әлеуметтік желілердеміз",
  l_Address: "Мекенжай",
  l_Email: "Эл. пошта",
  l_Need_a_consultation: "Кеңес керек пе?",
  l_Need_a_consultation_text: "Оқыту туралы және бос вакансиялар жайлы сұрақтарыңыз болса, төменде форманы толтырып, бізге жіберіңіз",
  l_Enter_phone_number: "Телефон нөмірін енгізіңіз",
  l_Corporate_hackathons_text: "Бұл кәсіби дағдыларды дамытуға, тәжірибе алмасуға және бірегей жобаларды жасауға тамаша мүмкіндік. Хакатон әлеміне қош келдіңіз!",
  l_At_hackathons: "Хакатондарда біз қатысушыларды дәстүрлі шекараларды бұзып, шығармашылық пен инновацияны көрсетуге шақырамыз.",
  l_Teams_have: "Командалардың бірге шешуге тиісті әртүрлі тақырыптар мен қиындықтарды таңдау мүмкіндігі бар. Олар технологияға, әлеуметтік мәселелерге, қоршаған ортаға, денсаулыққа және басқа салаларға қатысты болуы мүмкін.",
  l_During_the_hackathon: "Хакатон кезінде командалар ресурстармен және сарапшылар мен тәлімгерлердің қолдауымен қамтамасыз етіледі. Қатысушылар тәжірибе алмасуға, бір-бірінен үйренуге және жаңа технологиялық шешімдерге, өнімдерге немесе қызметтерге әкелетін өз жобаларын жасауға мүмкіндік алады.",
  l_One_of: "Хакатондардың басты артықшылықтарының бірі - желі құру және сарапшылар мен әлеуетті жұмыс берушілер алдында өз дағдыларыңызды көрсету мүмкіндігі.",
  l_Prizes_and: "Үздік командаларға олардың күш-жігері мен инновациялық идеяларын марапаттайтын сыйлықтар мен марапаттар да берілуі мүмкін.",
  l_We_invite: "Технологияға, инновацияға және күрделі мәселелерді шешуге қызығушылық танытқандардың барлығын хакатондарға қосылуға шақырамыз.",
  l_Business_trainers: "Бизнес-тренерлер",
  l_About_us_text: "RCDR тренингтері соңғы инновациялық тәсілдерді, соның ішінде онлайн немесе офлайн оқыту форматтарын пайдалана отырып, кәсіби, басқарушылық, коммуникативті, көшбасшылық, шығармашылық сияқты құзыреттердің әртүрлі түрлерін дамытуға бағытталған.",
  l_Advanced_training: "Біліктілікті арттыру бағдарламалары - жоғары және орта буын басшылары мен мұнай-газ саласының мамандары үшін қосымша кәсіби білім беру нысаны.",
  l_The_level:
    "Орталықтағы білім деңгейі халықаралық стандарттарға сай. Курстардың тақырыптары мұнай-газ компанияларының жетекші мамандарымен келісілген. Оқу жоспарын әзірлеушілер – өндірістік және педагогикалық тәжірибесі мол жоғары білікті мамандар, оның ішінде шетелдік, сонымен қатар Орталықтағы оқу үдерісіне қатысатын ғылыми-зерттеу институттарының жетекші мамандары.",
  l_The_center:
    "Орталықта білім сапасын арттыру үшін қажеттінің бәрі бар: оқу кабинеттері, практикалық және теориялық сабақтарға арналған көрнекі құралдар, бағдарламалық қамтамасыз ету, оқу-әдістемелік құралдар, үлгілердің жүйелі жинақтары, жұқа кесінділер, минералдар, тау жыныстары және қажетті анықтамалық материалдар.",
  l_The_center_offers: "Орталық өз клиенттеріне курстардың кең таңдауын ұсынады. Басым бағыттардың қатарына мыналар жатады",
  l_Oil_and_gas: "Мұнай-газ геологиясы және геофизика.",
  l_Subsoil_use: "Жер қойнауын пайдалану",
  l_Ecology_of_oil_and_gas_production: "Мұнай-газ өндірісінің экологиясы",
  l_Oil_and_gas_geology_and_geophysics: "Мұнай-газ геологиясы және геофизика",
  l_Modern_state_of: "Қазақстанның минералдық-шикізат базасының қазіргі жағдайы",
  l_Modern_methods_of_forecasting: "Қазақстандағы пайдалы қазбаларды болжаудың заманауи әдістері",
  l_Modern_methods_of_drilling: "Мұнай-газ кен орындарын бұрғылаудың, өндірудің және игерудің заманауи әдістері",
  l_Purpose_of_the_center: "Біздің мақсатымыз",
  l_Purpose_of_the_center_text:
    "Кадрлардың біліктілігін арттыру және қайта даярлау орталығы Еңбек нарығының қажеттілігін ескере отырып, базада құрылды, көмірсутек шикізатын және пайдалы қазбалардың басқа да түрлерін іздеудің, мұнай-газ өндірісінің экологиясының, сондай-ақ көмірсутек шикізатын бұрғылаудың, игерудің және өндірудің қазіргі заманғы геологиялық-геофизикалық әдістері бойынша кәсіби даярлау мен біліктілікті арттырудың бірқатар бағдарламаларын әзірледі және т. б...",
  l_Before_the_start_of_classes_it_is_necessary: "Сабақ басталғанға дейін",
  l_The_cost_of_individual: "Жеке оқытудың құны дәріс және практикалық сабақтардың қажетті санын, сондай-ақ бағдарламалық қамтамасыз ету мен аппаратураны пайдалануды қамтитын оқу жүктемесінің жалпы көлемінен анықталады.",
  l_Apply_for_training: "E-mail жіберу арқылы оқуға өтініш беріңіз. Өтінім берген кезде шот-фактураны ресімдеу үшін қажетті барлық мәліметтерді (ЖСК, БСН, кәсіпорынның заңды мекенжайы, жүк алушының атауы және пошталық мекенжайы) хабарлауды сұраймыз.",
  l_To_pay_for_participation: "Өтінімді алғаннан кейін үш жұмыс күні ішінде кәсіпорынға жазылатын және жіберілетін алдын ала төлем шоты бойынша курстардың қатысуын төлеңіз.",
  l_If_necessary_training_contract: "Қажет болған жағдайда оқыту шарты алдын ала жасалуы және кәсіпорынға жіберілуі мүмкін.",
  l_Pay_for_participation: "Оқу аяқталғаннан кейін үш жұмыс күні ішінде кәсіпорынға жазылатын және жіберілетін шот-фактура бойынша курстардың қатысуын төлеу.",
  l_Photo_gallery: "Фотогалерея",
  l_Your_name: " Сіздің атыңыз",
  l_Phone_number: "Телефон нөміріңіз",
  l_Wishes: "Қалаулар",
  l_Write_your_wishes: "Қалауларыңызды жазыңыз *",
  l_I_agree: "Мен жеке деректерді өңдеуге келісемін",
  l_Working_time: "Жұмыс уақыты",
  l_Social_media: "Әлеуметтік желілер",
  l_About_us_title: "Оқу орталығы қызметінің негізгі жарғылық бағыттары",
  l_What_will_you_get: "Сіз не аласыз?",
  l_Many_years_of_experience: "Көп жылдық тәжірибе",
  l_Many_years_of_experience_text: "Кадрлық іс жүргізу бойынша семинарлар өткізудің көп жылдық тәжірибесі бізге өз жұмысымызда өз тәжірибемізді ғана емес, сонымен қатар еліміздің әртүрлі өңірлерінде тыңдаушыларымыздың қолданыстағы еңбек заңнамасын қолдану тәжірибесіне сүйенуге мүмкіндік береді;",
  l_No_risks: "Қауіпсіз",
  l_No_risks_text: "Біздің команда кәсіпкерлерге жан-жақты көмек көрсетеді, бұл кадрлық іс жүргізу кезінде ықтимал жағымсыз тәуекелдерді болдырмауға мүмкіндік береді.",
  l_Saving_time: "Уақытты үнемдеу",
  l_Saving_time_text: "Біздің қызметкерлер қысқа мерзімде Сіздің бизнесіңіздің ерекшеліктері мен ерекшеліктерін ескере отырып, кадрлық өндірістің тиімді жүйесін әзірлейді және енгізеді.",
  l_Solutions_in_field_of: "Aутсорсинг және персоналды басқару саласындағы шешімдер.",
  l_Legal_and_personnel: "Компания құжаттарының құқықтық және кадрлық сараптамасы",
  l_Order_kp: "Тапсырыс КП",
  l_If_you_have: "Егер сізде",
  l_Order_call: "Қоңырауға тапсырыс беру",
  l_Order: "Тапсырыс беру",
  l_Manager: "Менеджер",
  l_Do_not_have_your_own_HR: "Сізде кадр жоқ па?",
  l_It_is_planned_to_check: "Еңбек инспекторын тексеру жоспарланған",
  l_Not_sure_if_you_comply: "Еңбек заңнамасын сақтайтындығыңызға сенімді емессіз",
  l_Your_staff_is_less_than: "Сіздің қызметкерлеріңіз 50 адамнан аз",
  l_HR_work_is_conducted: "Кадрлық жұмысты бухгалтер немесе басқа адам жүргізеді",
  l_There_is_a_risk_of_employees: "Қызметкерлердің сотқа жүгіну қаупі бар",
  l_Personnel_selection_is_necessary: "Кадрларды іріктеу қажет",
  l_Restore_office_work: "Іс қағаздарын қалпына келтіру",
  l_Calculate_bonuses: "Айлық және бонустарды есептеу",
  l_Consulting_sub_title: "Оқыту арқылы сіздің бизнесіңізді жан-жақтан қорғау және тиімді дамыту",
  l_In_order_organize_additional: "Қосымша білім беруді ұйымдастыру және мұнай-газ және қаржы секторларының кез келген деңгейдегі мамандары үшін біліктілікті арттыру курстарын өткізу мақсатында.",
  l_The_courses_training_center: "Оқу орталығының курстары– бұл сізге ыңғайлы оқу форматындағы қосымша және сапалы білім. Тренингтерді практикалық тәжірибе мен теориялық әлеуетті біріктіретін үздік оқытушылар мен сарапшылар жүргізеді.",
  l_Training_center_conducts_adapts: "Оқу орталығы сіздің компанияңыздың келесі бағыттар бойынша даму стратегиялары мен ағымдағы міндеттерін ескере отырып, сіздің қажеттіліктеріңізге сәйкес тренингтер өткізеді және бейімдейді",
  l_Direction: "Бағыттар",
  l_Oil_and_gas_engineering: "Мұнай-газ инженериясы",
  l_Oil_and_gas_geology: "Мұнай-газ геологиясы",
  l_Legalization_republic_kazakhstan: "Мұнай-газ саласындағы ҚР Заңнамасы",
  l_Project_management: "Жобаларды басқару",
  l_Finance_taxation: "Қаржы және салық салу",
  l_Accounting_oil_gas_industry: "Мұнай-газ саласындағы есеп",
  l_Assessment_oil_gas_industry: "Мұнай-газ өнеркәсібіндегі бағалау",
  l_Sales_negotiations_communications: "Сату, келіссөздер, коммуникациялар",
  l_Team_build_HR: "Team Building және HR",
  l_Marketing_PR: "Маркетинг және PR",
  l_Environmental_protection: "Қоршаған ортаны қорғау",
  l_Place_of_training: "Оқу орны",
  l_Location_Company: "Компанияның орналасқан жері",
  l_Customer_territory: "Тапсырыс берушінің аумағы",
  l_Field_courses_abroad_Customer: "Шетелге шығу курстары (Тапсырыс берушімен келісім бойынша)",
  l_Training_format: "Оқыту форматы",
  l_Open_training_formation: "Ашық тренинг (әр түрлі компаниялардың 8 қатысушысынан топ құру)",
  l_Corporate_training_formation: "Корпоративтік тренинг (бір компаниядан 20 адамға дейін топ құру)",
  l_The_cost_training_varies_depending: "Оқу құны әр түрлі болады",
  l_Duration_training: "Оқу ұзақтығы",
  l_Venue_format_event: "Өткізу орындары мен форматтары",
  l_Number_participants_group: "топтағы мүшелер саны",
  l_About_us_title1: "қосымша кәсіби бағдарламаларды жүзеге асыру (бағдарламалар",
  l_About_us_title2: "біліктілігін арттыру, кәсіби қайта даярлау бағдарламалары);",
  l_About_us_title3: "біліктілігін арттыруды ұйымдастыру және",
  l_About_us_title4: "қызметкерлерді, басшыларды және мамандарды кәсіби қайта даярлау",
  l_About_us_title5: "қосымша кәсіби біліктілікті арттыру бағдарламалары, кәсіби қайта даярлау бағдарламалары бойынша ұйымдар",
  l_About_us_title6: "Қызметкерлердің біліктілігін арттыру және қайта даярлау бойынша білім беру қызметтерінің сапасын арттыру.",
  l_Answers_to_our_frequently_asked_questions: "Жиі қойылатын сұрақтарымызға жауаптар",
  l_Course_structure: "Курс құрылымы",
  l_Form_of_stufy: "Оқу форматы",
  l_fill_time_block_corporate_training: "күндізгі, блоктық, корпоративтік оқыту",
  l_Teaching_methodology: "Оқыту әдістемесі",
  l_Teaching_methodology1: "максималды тиімділікті қамтамасыз ету үшін оқыту түрінде жүзеге асырылады: лекциялар, презентациялар, практикалық жаттығулар.",
  l_course_structure1: "Қатысушылар формаға белсенді түрде қатысады деп күтілуде",
  l_course_structure2: "топта проблемалық сұрақтарды талқылау;",
  l_course_structure3: "практикалық тапсырмаларды орындау;",
  l_course_structure4: "тау жыныстарының шлифтері мен үлгілерінің жүйелі коллекцияларын зерттеу;",
  l_course_structure5: "заманауи бағдарламалық жасақтаманы зерттеу.",
  l_Duration_of_study: "Оқу ұзақтығы",
  l_from_2_days_to_2_week: "2 күннен 2 аптаға дейін",
  l_Reporting_Form: "Есеп беру формасы",
  l_Reporting_Form1: "білімді бекітуге арналған қорытынды тестілеу, емтихан",
  l_Final_document: "Қорытынды құжат",
  l_Final_document1: "біліктілікті арттыру курсын бітіргені туралы сертификат",
  l_FInal_document_header: "Қорытынды құжат: Курсты аяқтау туралы сертификат.",
  l_Final_document_text1: "Жеке бағдарламалар бойынша және клиенттердің сұранысы бойынша оқыту",
  l_Final_document_text2: "Бағдарлама тыңдаушылардың сұраныстарына сәйкес өзгертілуі және бейімделуі мүмкін!",
  l_Final_document_text3: "Клиенттеріміздің тілектерін ескере отырып, Орталық кәсіпорындардың жеке келісім-шарттары мен өтінімдері бойынша жұмыс жүргізеді",
  l_Course_topics_may_be_adjusted_to_reflect_following: "Курстардың тақырыбы келесілерді ескере отырып реттелуі мүмкін",
  l_production_needs_and_customer_issues: "өндіріс қажеттіліктері және тұтынушы мәселелері,",
  l_basic_education: "негізгі білім,",
  l_required_deadlines: "талап етілген мерзімдер",
  l_form_of_learning: "оқыту нысаны",
  l_For_each_category_of_listeners_possible: "Тыңдаушылардың әр санаты үшін мүмкін",
  l_For_each_category_text_indi: "Жеке оқу-тақырыптық сабақ жоспарларын құру,",
  l_For_each_category_text_program: "бағдарлама шолу сипатында болуы мүмкін, қажетті сұрақтардың минимумын қамтуы және тар бағытта болуы мүмкін,",
  l_For_each_category_text_face: "жеке тұлғалармен жұмыс жеке өтініш бойынша жүзеге асырылады,",
  l_For_each_category_text_cource: "жеке курстарды ағылшын тілінде оқуға болады.",
  l_For_each_category_text_time: "оқу уақыты, орны және ұзақтығы келісіледі.",
  l_Conditions_of_education: "Білім беру шарттары",
  l_Conditions_of_education_title: "Жоғары немесе орта кәсіптік білімі бар мамандарды даярлауды және біліктілігін арттыруды жүргізеді.",
  l_Consitions_of_education_price: "Біліктілікті арттыру курстарында оқу құнына мыналар кіреді:",
  l_trainin_of_listeners: "тыңдаушыларды оқыту;",
  l_handout_material: "үлестірме материал;",
  l_coffee_breaks_during_breaks: "үзіліс кезінде кофе-брейктер.",
  l_Discussion_of_the_fixed_material: "Бекітілген материалды талқылау",
  l_Onsite_training: "Кәсіпорындарға барып оқыту",
  l_Opsite_training_text:
    "Тыңдаушылардың қалауы бойынша біздің мамандар орталықтың бағдарламалары бойынша оқыту үшін тапсырыс берушінің кәсіпорнына барады. Бұл жағдайда сабақтың Жеке оқу-тақырыптық жоспарлары жасалуы мүмкін. Тыңдаушылар үлестірме материалмен және қажетті анықтамалық және әдістемелік әдебиеттермен қамтамасыз етіледі.",
  l_Open_training_price: "Орындарға барып оқу құны жоспарланған оқу жүктемесінің сағат санына, тыңдаушылар санына, сондай-ақ оқытушылардың іссапар шығындарына негізделе отырып айқындалады.",
  l_Conditions_for_the_audience: "Егер курс Тапсырыс берушінің базасында өтсе, аудиторияларға қойылатын шарттар",
  l_Conditions_for_listener: "топтағы тыңдаушылар саны: 8-15 қызметкер.",
  l_Consitions_for_pk: "презентацияларды көрсету үшін проектормен және ноутбукпен жабдықталған аудитория;",
  l_Consitions_for_wall_board: "жазбаларға арналған қабырға тақтасы (маркерлер немесе бор);",
  l_Privacy_policy: "Құпиялылық саясаты",
  l_Please_enter_your_name: "Атыңызды енгізіңіз!",
  l_Please_enter_your_phone_number: "Телефон нөміріңізді енгізіңіз!",
  l_Please_check: "Тандаңыз!",
  l_Successfully_sent: "Сіздің сұрауыңыз қабылданды. Біз сізге жақын арада хабарласамыз.",
  l_Consulting_info_text:
    "Орталықтағы оқу деңгейі әлемдік стандарттарға сәйкес келеді. Курстардың тақырыбы мұнай-газ компанияларының жетекші мамандарымен келісілді. Оқу бағдарламаларын әзірлеушілер үлкен өндірістік және педагогикалық тәжірибесі бар жоғары білікті 1 маман, оның ішінде шетелдік мамандар, сондай-ақ орталықта оқу процесіне тартылатын ғылыми-зерттеу институттарының жетекші мамандары болып табылады.",
  l_Consulting_info_text_part2: "Біліктілікті арттыру курстарының бағдарламалары жоғары және орта буын басшылары үшін және мұнай-газ өндіру кешені саласындағы мамандар үшін қосымша кәсіптік білім беру нысаны болып табылады....",
  l_Consulting_info_text_part3:
    "Орталықтағы оқу деңгейі әлемдік стандарттарға сәйкес келеді. Курстардың тақырыбы мұнай-газ компанияларының жетекші мамандарымен келісілді. Оқу бағдарламаларын әзірлеушілер үлкен өндірістік және педагогикалық тәжірибесі бар жоғары білікті мамандар, оның ішінде шетелдік мамандар, сондай-ақ орталықта оқу процесіне тартылатын ғылыми-зерттеу институттарының жетекші мамандары болып табылады. Біліктілікті арттыру курстарының бағдарламалары жоғары және орта буын басшылары үшін және мұнай-газ өндіру кешені саласындағы мамандар үшін қосымша кәсіптік білім беру нысаны болып табылады.",
  l_Social_medias: "Әлеуметтік желілер",
  l_Leave_request: "Сұраныс қалдырыңыз, біз сізге қайта қоңырау шаламыз",
};
