<template>
  <div class="client-page">
    <the-header></the-header>
    <router-view></router-view>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";
export default {
  components: {
    TheFooter,
    TheHeader,
  },
};
</script>

<style lang="scss" scoped>
.client-page {
}
</style>
