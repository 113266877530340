<template>
  <div class="set-language-area">
    <div class="align-center pointer" @click.prevent>
      <div class="lang" :class="{ 'lang-active': langTag == 'kz' }" @click="setLanguage('kz')">Қаз</div>
      <div class="lang" :class="{ 'lang-active': langTag == 'rus' }" @click="setLanguage('rus')">Рус</div>
      <div class="lang" :class="{ 'lang-active': langTag == 'en' }" @click="setLanguage('en')">Eng</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SetLanguageBox",
  computed: {
    langTag() {
      return this.$i18n.locale;
    },
  },
  methods: {
    setLanguage(e) {
      this.$i18n.locale = e;
      localStorage.setItem("currentLang", e);
    },
  },
};
</script>

<style lang="scss" scoped>
$bigWin: 900px;

.set-language-area {
  padding: 6px 10px 6px 8px;
  border-radius: 24px;

  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #1c1b1f;
  .lang {
    display: block;
    margin-right: 25px;
    font-weight: 500;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    transition: color 0.4s ease-in-out, border-bottom 0.3s ease;
    &:last-child {
      margin-right: 0;
    }

    &.lang-active {
      transition: color 0.4s ease-in-out, border-bottom 0.5s ease;
      color: #0a3263;
      border-bottom: 1px solid #0a3263;
    }
  }

  @media (max-width: $bigWin) {
    padding: 3px 5px 3px 4px;
  }
}
</style>
