import { defineStore } from "pinia";

export default defineStore("state", {
  state: () => ({
    isLoading: false,
  }),
  actions: {
    setLoading(loading) {
      this.isLoading = loading;
    },
  },
});
