<template>
  <div class="admin-page">
    <a-drawer v-model:open="collapsed" width="250" class="custom-class" :closable="false" placement="left" :bodyStyle="{ background: '#031629', padding: 0, overflowY: 'auto', height: '100vh' }">
      <menu-box @select="collapsed = !collapsed"></menu-box>
    </a-drawer>
    <a-layout :style="{ height: 100 + '%' }">
      <a-layout-sider :style="{ overflowY: 'auto' }" v-if="$root.laptop" v-model:collapsed="collapsed" :trigger="null" collapsible>
        <div class="site-logo" v-show="!collapsed">
          <img class="img" src="" alt="" />
        </div>
        <menu-box></menu-box>
        <div class="bt-h"></div>
      </a-layout-sider>
      <a-layout class="layout-content">
        <a-layout-header class="header">
          <div v-if="!$root.laptop" class="header-left-icon" :class="{ 'mobile-icon-size': $root.laptop == false }">
            <span class="material-symbols-outlined" @click="collapsed = !collapsed"> menu </span>
          </div>
          <div class="header-right">
            <div class="right-item">
              <a-dropdown>
                <div class="align-center pointer" @click.prevent>
                  <span class="material-symbols-outlined"> person </span>
                  <div class="ellipsis" style="max-width: 110px">
                    {{ username }}
                  </div>
                </div>
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click="logOut()">
                      <span class="pointer">Выйти</span>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </div>
        </a-layout-header>
        <a-layout-content class="router-area">
          <router-view></router-view>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import MenuBox from "@/components/MenuBox.vue";
import useUserStore from "@/stores/user";
import { mapWritableState, mapActions } from "pinia";

export default {
  components: {
    MenuBox,
  },
  data() {
    return {
      collapsed: false,
      username: "",
    };
  },
  computed: {
    ...mapWritableState(useUserStore, ["userInfo"]),
  },
  mounted() {
    this.username = this.userInfo.email;
  },
  methods: {
    ...mapActions(useUserStore, ["signOut"]),
    logOut() {
      this.signOut();
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-page {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  .layout-content {
    overflow-y: hidden;
    .header {
      background: #fff;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;

      .header-left-icon {
      }
      .mobile-icon-size {
        font-size: 30px;
      }
      .header-right {
        display: flex;
        align-items: center;
        position: absolute;
        right: 22px;

        .right-item {
          margin-left: 0px;
        }
      }
    }
  }
  .router-area {
    overflow-y: auto;
    margin: 10px;
    padding: 10px;
    background: #fff;
    min-height: 280px;
    box-sizing: border-box;
    //overflow-x: hidden;
  }
}
</style>
