export default {
  l_News: "News",
  l_More_details: "More details",
  l_Consulting_services: "Consulting services",
  l_Training_and_development: "Training and development",
  l_Consulting_services_text: "If you have questions about the format or don't know what to choose, leave your number - we will call to answer all your questions.",
  l_Send: "Send",
  l_Personnel_support: "Personnel support",
  l_Read_more: "Read more",
  l_Seminars_and_trainings: "Seminars and trainings",
  l_Corporate_hackathons: "Corporate hackathons",
  l_About_the_center: "About the center",
  l_About_the_center_text: "Programs of professional development courses are a form of additional professional education for senior and middle managers and for specialists in the oil and gas industry.",
  l_Learn_more: "Learn more",
  l_years: "years",
  l_Practice: "Practice",
  l_Teachers: "Teachers",
  l_Regular_customers_in_the_face_of_the_company: "Regular customers in the face of the company",
  l_Success: "Success",
  l_Our_business_trainers: "Our business trainers",
  l_Our_business_trainers_text: "Experienced and highly qualified specialists work for you, who are experts in various fields of economics, finance, law.",
  l_Consultation: "Consultation",
  l_We_are_trusted: "We are trusted",
  l_We_are_trusted_text: "We value our customers and always focus on long-term cooperation",
  l_Why_are_we: "Why are we?",
  l_Why_are_we_text: "The main values of our company are stability, reliability and confidence in the future!",
  l_Offline_and_online_learning_format: "Offline and online learning format",
  l_Individual_approach_to_each_customer: "Individual approach to each Customer",
  l_Organizing_Corporate_hackathons: "Organizing Corporate Hackathons",
  l_Many_years_of_successful_experience_of_the_team_in_the_market_of_educational_services: "Many years of successful team experience in the educational services market",
  l_Organization_of_corporate_trainings_on_the_territory_of_the_Customer_and_with_travel_abroad: "Organization of corporate trainings on the territory of the Customer and with travel abroad",
  l_Full_HR_support_and_legal_expertise: "Full HR support and legal expertise",
  l_Certificates: "Certificates",
  l_Certificates_text: "Our educational institution meets high quality standards and offers listeners quality trainings, seminars and many other continuing education courses.",
  l_Main: "Main",
  l_About_us: "About us",
  l_Certificate: "Certificate",
  l_Gallery: "Gallery",
  l_Digest: "Digest",
  l_Contacts: "Contacts",
  l_We_are_in_social_media: "We are in social media",
  l_Address: "Address",
  l_Email: "Email",
  l_Need_a_consultation: "Need a consultation?",
  l_Need_a_consultation_text: "If you have any questions about training and vacancies, please fill out the form below and send it to us",
  l_Enter_phone_number: "Enter phone number",
  l_Corporate_hackathons_text: "This is a great opportunity to develop professional skills, share experience and create unique projects. Welcome to the world of hackathons!",
  l_At_hackathons: "At hackathons, we encourage participants to break through traditional boundaries and show their creativity and innovation.",
  l_Teams_have: "Teams have the ability to choose between different themes and challenges that they must tackle together. These can be related to technology, social issues, environment, health and other areas.",
  l_During_the_hackathon:
    "During the hackathon, teams are provided with resources and support from experts and mentors. Participants have the opportunity to exchange experiences, learn from each other and create their own projects that can lead to new technological solutions, products or services.",
  l_One_of: "One of the main benefits of hackathons is networking and the opportunity to showcase your skills in front of experts and potential employers.",
  l_Prizes_and: "Prizes and awards can also be given to the best teams, rewarding their efforts and innovative ideas.",
  l_We_invite: "We invite everyone interested in technology, innovation and complex problem solving to join our hackathons.",
  l_Business_trainers: "Business trainers",
  l_About_us_text: "RCDR Trainings is focused on the development of various types of competencies, such as professional, managerial, communicative, leadership, creative, using the latest innovative approaches, including online or offline learning formats.",
  l_Advanced_training: "Advanced training programs are a form of additional professional education for senior and middle managers and for specialists in the oil and gas industry.",
  l_The_level:
    "The level of education at the Center meets international standards. The topics of the courses are agreed with the leading specialists of oil and gas companies. The developers of the training programs are highly qualified specialists with extensive production and teaching experience, including foreign ones, as well as leading specialists from research institutes involved in the educational process in the center.",
  l_The_center: "The Center has everything necessary to improve the quality of education: classrooms, visual aids for practical and theoretical classes, software, teaching aids, systematic collections of samples, thin sections, minerals, rocks and necessary reference materials.",
  l_The_center_offers: "The center offers a wide range of courses to its clients. Among the priority areas are the following",
  l_Oil_and_gas: "Petroleum geology and geophysics.",
  l_Subsoil_use: "Subsoil use",
  l_Ecology_of_oil_and_gas_production: "Ecology of oil and gas production",
  l_Oil_and_gas_geology_and_geophysics: "Petroleum Geology and Geophysics",
  l_Modern_state_of: "Modern state of the mineral resource base of Kazakhstan",
  l_Modern_methods_of_forecasting: "Modern methods of forecasting minerals in Kazakhstan",
  l_Modern_methods_of_drilling: "Modern methods of drilling, production and development of oil and gas fields",
  l_Purpose_of_the_center: "Purpose of the Center",
  l_Purpose_of_the_center_text:
    "The Center for Advanced Training and Retraining of Personnel was created on the basis of taking into account the needs of the labor market, developed a number of programs for professional training and advanced training in modern geological and geophysical methods for prospecting for hydrocarbons and other types of minerals, the ecology of oil and gas production, as well as drilling, development and production hydrocarbon raw materials, etc....",
  l_Before_the_start_of_classes_it_is_necessary: "It is necessary before the start of classes",
  l_The_cost_of_individual: "The cost of individual training is determined from the total amount of training load, including the required number of lectures and practical classes, as well as the use of software and equipment.",
  l_Apply_for_training: "Apply for training by sending it by e-mail. When submitting an application, we also ask you to provide all the information necessary for issuing an Invoice (IIC, BIN, Legal address of the company, name and postal address of the consignee).",
  l_To_pay_for_participation: "Pay for the participation of courses on a prepayment invoice, which is issued and sent to the company within three working days after receiving the application.",
  l_If_necessary_training_contract: "If necessary, a training contract can be concluded in advance and sent to the company.",
  l_Pay_for_participation: "Pay for the participation of courses on an invoice that it is discharged and sent to the company within three working days after graduation",
  l_Photo_gallery: "Photo gallery",
  l_Your_name: "Your name",
  l_Phone_number: "Phone Number",
  l_Wishes: "Wishes",
  l_Write_your_wishes: "Write your wishes *",
  l_I_agree: "I agree to the processing of personal data",
  l_Working_time: "Working time",
  l_Social_media: "Social networks",
  l_About_us_title: "The main statutory activities of the Training Center are",
  l_What_will_you_get: "What will you get?",
  l_Many_years_of_experience: "Many years of experience",
  l_Many_years_of_experience_text:
    "Many years of experience in conducting seminars on personnel records management, allows us to use not only our own experience in our work, but also to rely on the experience of applying the current labor legislation by our students in various regions of our country;",
  l_No_risks: "No risks",
  l_No_risks_text: "Our team provides comprehensive comprehensive assistance to entrepreneurs, which avoids possible negative risks when conducting personnel records management.",
  l_Saving_time: "Saving time",
  l_Saving_time_text: "Our employees will develop and implement an effective personnel production system in a short time, taking into account the specifics and specifics of your business.",
  l_Solutions_in_field_of: "Solutions in the field of outsourcing and personnel management.",
  l_Legal_and_personnel: "Legal and personnel expertise of company documents",
  l_Order_kp: "Order KP",
  l_If_you_have: "If you have",
  l_Order_call: "order a call",
  l_Order: "Order",
  l_Manager: "Manager",
  l_Do_not_have_your_own_HR: "Don't have your own HR officer?",
  l_It_is_planned_to_check: "A labor inspector check is planned",
  l_Not_sure_if_you_comply: "Not sure that you comply with labor legislation",
  l_Your_staff_is_less_than: "Your staff is less than 50 people",
  l_HR_work_is_conducted: "HR work is conducted by an accountant or another person",
  l_There_is_a_risk_of_employees: "There is a risk of employees going to court",
  l_Personnel_selection_is_necessary: "Recruitment is required",
  l_Restore_office_work: "Restore office work",
  l_Calculate_bonuses: "Calculate salary and bonuses",
  l_Consulting_sub_title: "Comprehensive protection and effective development of your business!",
  l_In_order_organize_additional: "In order to organize additional education and conduct advanced training courses for specialists of any level in the oil and gas and financial sectors.",
  l_The_courses_training_center: "Training Center courses are additional and high–quality knowledge in a convenient training format for you. The trainings are conducted by the best teachers and experts who integrate practical experience and theoretical potential.",
  l_Training_center_conducts_adapts: "The Training Center conducts and adapts trainings to your needs, taking into account the development strategies and current tasks of your company in the following areas",
  l_Direction: "Direction",
  l_Oil_and_gas_engineering: "Oil and gas engineering",
  l_Oil_and_gas_geology: "Oil and gas geology",
  l_Legalization_republic_kazakhstan: "Legislation of the Republic of Kazakhstan in the oil and gas industry",
  l_Project_management: "Management projects",
  l_Finance_taxation: "Finance and taxation",
  l_Accounting_oil_gas_industry: "Accounting in the oil and gas industry",
  l_Assessment_oil_gas_industry: "Assessment in the oil and gas industry",
  l_Sales_negotiations_communications: "Sales, Negotiations, communications",
  l_Team_build_HR: "Team Building and HR",
  l_Marketing_PR: "Marketing and PR",
  l_Environmental_protection: "Environmental Protection",
  l_Place_of_training: "Training Venue",
  l_Location_Company: "Location of the Company",
  l_Customer_territory: "Customer's territory",
  l_Field_courses_abroad_Customer: "Field courses abroad (In agreement with the Customer)",
  l_Training_format: "Training format",
  l_Open_training_formation: "Open training (formation of a group of 8 participants from different companies)",
  l_Corporate_training_formation: "Corporate training (formation of a group of up to 20 people from one company)",
  l_The_cost_training_varies_depending: "The cost of training varies depending on",
  l_Duration_training: "Duration of training",
  l_Venue_format_event: "Venue and format of the event",
  l_Number_participants_group: "Number of participants in the group",
  l_About_us_title1: "realization of additional professional programs (programs",
  l_About_us_title2: "professional development, vocational retraining programs);",
  l_About_us_title3: "organizing and conducting advanced training and",
  l_About_us_title4: "professional retraining of employees, managers and specialists",
  l_About_us_title5: "organizations for additional professional advanced training programs, professional retraining programs",
  l_About_us_title6: "Improving the quality of educational services for advanced training and retraining of employees.",
  l_Answers_to_our_frequently_asked_questions: "Answers to our frequently asked questions",
  l_Course_structure: "Course structure",
  l_Form_of_stufy: "Form of stufy",
  l_fill_time_block_corporate_training: "full-time, block, corporate training",
  l_Teaching_methodology: "Teaching methodology",
  l_Teaching_methodology1: "to ensure maximum efficiency, training is carried out in the form of training: lectures, presentations, practical exercises.",
  l_course_structure1: "Participants are expected to actively participate in the form",
  l_course_structure2: "discussion of problematic issues in a group;",
  l_course_structure3: "performing practical tasks;",
  l_course_structure4: "study of systematic collections of thin sections and rock samples;",
  l_course_structure5: "learning about modern software.",
  l_Duration_of_study: "Duration of study",
  l_from_2_days_to_2_week: "from 2 days to 2 weeks",
  l_Reporting_Form: "Reporting Form",
  l_Reporting_Form1: "final testing to consolidate knowledge, examination",
  l_Final_document: "Final Document",
  l_Final_document1: "certificate of completion of advanced training course",
  l_FInal_document_header: "Final Document: Course Completion Certificate.",
  l_Final_document_text1: "Training according to individual programs and at the request of clients",
  l_Final_document_text2: "The program is subject to change and adaptation to listeners' requests!",
  l_Final_document_text3: "Taking into account the wishes of our clients, the Center carries out work on individual contracts and applications of enterprises",
  l_Course_topics_may_be_adjusted_to_reflect_following: "Course topics may be adjusted to reflect the following",
  l_production_needs_and_customer_issues: "production needs and customer issues,",
  l_basic_education: "basic education,",
  l_required_deadlines: "required deadlines",
  l_form_of_learning: "form of learning",
  l_For_each_category_of_listeners_possible: "For each category of listeners possible",
  l_For_each_category_text_indi: "composition of individual lesson plans,",
  l_For_each_category_text_program: "the program can be of an overview character, covering a minimum of necessary questions, and be narrowly focused,",
  l_For_each_category_text_face: "work with private persons is carried out according to personal application,",
  l_For_each_category_text_cource: "separate courses can be read in English.",
  l_For_each_category_text_time: "reading time, place and duration are negotiable.",
  l_Conditions_of_education: "Conditions of education",
  l_Conditions_of_education_title: "The center conducts training and advanced training for specialists with higher or secondary vocational education.",
  l_Consitions_of_education_price: "Includes in the cost of tuition for continuing education courses:",
  l_trainin_of_listeners: "training of listeners;",
  l_handout_material: "handout_material;",
  l_coffee_breaks_during_breaks: "coffee breaks during breaks.",
  l_Discussion_of_the_fixed_material: "Discussion of the fixed material",
  l_Onsite_training: "Onsite training",
  l_Opsite_training_text:
    "At the request of the listeners, our specialists travel to the customer's enterprise for training according to the Center's programs. In this case, individual educational and thematic lesson plans can be drawn up. Students are also provided with handouts and the necessary reference and methodological literature.",
  l_Open_training_price: "The cost of on-site training is determined based on the number of hours of the planned teaching load, the number of students, as well as the travel expenses of teachers.",
  l_Conditions_for_the_audience: "Conditions for the audience, if the course is held on the basis of the customer",
  l_Conditions_for_listener: "number of listeners in the group: 8 – 15 employees.",
  l_Consitions_for_pk: "an auditorium equipped with a projector and a laptop to demonstrate presentations;",
  l_Consitions_for_wall_board: "wall board for writing (markers or chalk);",
  l_Privacy_policy: "Privacy policy",
  l_Please_enter_your_name: "Please, enter you name!",
  l_Please_enter_your_phone_number: "Please, enter you phone number!",
  l_Please_check: "Please, check!",
  l_Successfully_sent: "Your request has been accepted. We will contact you shortly.",
  l_Consulting_info_text:
    "The level of education at the Center meets international standards. The subjects of the courses are coordinated with the leading specialists of oil and gas companies. Curriculum developers are highly qualified 1 specialists with extensive production and teaching experience, including foreign ones, as well as leading specialists from research institutes involved in the educational process at the Cente",
  l_Consulting_info_text_part2: "Advanced training course programs are a form of additional professional education for senior and middle managers and for specialists in the field of the oil and gas complex....",
  l_Consulting_info_text_part3:
    "The level of education at the Center meets international standards. The subjects of the courses are coordinated with the leading specialists of oil and gas companies. The curriculum developers are highly qualified specialists with extensive production and teaching experience, including foreign ones, as well as leading specialists from research institutes involved in the educational process at the Center. Advanced training course programs are a form of additional professional education for senior and middle managers and for specialists in the oil and gas industry.",
  l_Social_medias: "Social media",
  l_Leave_request: "Leave a request, we will call you back",
};
