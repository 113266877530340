<template>
  <div class="contact-form" :class="{ 'contact-modal': isModal }">
    <a-form class="form" ref="form" :model="form" name="basic" autocomplete="off" @finish="onFinish">
      <div class="info" v-if="sent">{{ $t("l_Successfully_sent") }}</div>
      <a-form-item class="box" name="name" :rules="[{ required: true, message: $t('l_Please_enter_your_name') }]">
        <p class="label">{{ $t("l_Your_name") }}</p>
        <a-input class="input" v-model:value="form.name" @change="sent = false" />
      </a-form-item>

      <a-form-item class="box" name="phone" :rules="[{ required: true, message: $t('l_Please_enter_your_phone_number') }]">
        <p class="label">{{ $t("l_Phone_number") }}</p>
        <a-input class="input" v-model:value="form.phone" @change="sent = false" />
      </a-form-item>

      <a-form-item class="box" name="comment">
        <p class="label">{{ $t("l_Wishes") }}</p>
        <a-textarea class="input" :autosize="{ minRows: 4, maxRows: 6 }" v-model:value="form.comment" @change="sent = false" />
      </a-form-item>

      <a-form-item name="agree" :rules="[{ required: true, message: $t('l_Please_check') }]">
        <a-checkbox class="input checkbox" v-model:checked="form.agree" @change="sent = false">{{ $t("l_I_agree") }}</a-checkbox>
      </a-form-item>

      <a-form-item>
        <a-button class="input submit-btn" type="primary" html-type="submit">{{ $t("l_Send") }}</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { mailCollection } from "@/includes/firebase";
import { addDoc } from "firebase/firestore";
import { vMaska } from "maska";
export default {
  directives: { maska: vMaska },
  props: {
    isModal: {
      type: Boolean,
      required: true,
    },
    isPersonal: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    this.initialPhoneNumber();
  },
  data() {
    return {
      form: {
        name: null,
        phone: null,
        comment: null,
        agree: true,
      },
      sent: false,
    };
  },
  methods: {
    initialPhoneNumber() {
      if (this.isPersonal?.phoneNumber) {
        this.form.phone = this.isPersonal.phoneNumber;
      }
    },
    async sendEmail() {
      await addDoc(mailCollection, {
        to: ["rcdr@rcdr.kz"],
        message: {
          subject: "Контакты",
          html: `
            <div>
              <div>Имя: ${this.form.name}</div>  
              <div>Номер: ${this.form.phone}</div>  
              <div>Комментарий: ${this.form.comment}</div>  
            </div>
          `,
        },
      });
    },
    onFinish() {
      this.sendEmail();
      this.sent = true;
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.input:active,
.input:focus,
.info:hover {
  // border: none;
  outline: none;
  box-shadow: none;
}
.contact-form {
  padding: 56px 18px 19px 24px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 391px;
  width: 100%;

  &.contact-modal {
    box-shadow: none;
    width: 100%;
    padding: 0;
  }
  .form {
    margin-top: 30px;
    .info {
      padding: 4px 12px;
      border-radius: 8px;
      background: rgba(27, 137, 24, 0.08);
      color: var(--black, #1c1b1f);
      margin-bottom: 20px;
    }
    .box {
      margin-bottom: 20px;
      .label {
        margin-bottom: 5px;
        margin-left: 6px;
        color: #000;
        font-size: 18px;
        font-weight: 500;
      }
      .checkbox {
        width: 20px;
        height: 20px;
        border-radius: 3px;
        accent-color: #a02724;
      }
    }
    .submit-btn {
      height: 40px;
      padding: 12px 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      border-radius: 10px;
      background: #a02724;
      border: none;
      width: 100%;
    }
  }
}
</style>
