<template>
  <div class="base-page no-select">
    <div class="loading" v-if="isLoading">
      <div class="loading-mask"></div>
      <div class="loading-content" role="status">
        <a-spin class="loading-icon" size="large" />
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import useStateStore from "@/stores/state";
import { mapActions, mapState } from "pinia";

export default {
  data() {
    return {
      laptop: true,
    };
  },
  computed: {
    ...mapState(useStateStore, ["isLoading"]),
  },
  mounted() {
    this.watchChangeSize();
    window.onresize = () => {
      this.watchChangeSize();
    };
  },
  methods: {
    ...mapActions(useStateStore, ["setLoading"]),
    watchChangeSize() {
      let offsetWid = document.documentElement.clientWidth;
      if (offsetWid >= 1000 && this.laptop === false) {
        this.laptop = true;
      } else if (offsetWid < 900 && this.laptop === true) {
        this.laptop = false;
      }
      offsetWid = null;
    },
  },
};
</script>

<style lang="scss">
.base-page {
  .loading {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999999;

    .loading-mask {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
    }

    .loading-content {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 2;
      display: flex;

      .loading-icon {
        margin: auto;
      }
    }
  }
}
</style>
