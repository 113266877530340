// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { collection, getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAmbff3t4ZV3roEKHyAWPBIeb6aiENv6Mg",
  authDomain: "rcdr-c105f.firebaseapp.com",
  projectId: "rcdr-c105f",
  storageBucket: "rcdr-c105f.appspot.com",
  messagingSenderId: "16768074945",
  appId: "1:16768074945:web:398ab9d4a3270ca0e34040",
  measurementId: "G-DVLWX83TH8",
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const certificatesCollection = collection(db, "certificates");
export const galleryCollection = collection(db, "gallery");
export const digestCollection = collection(db, "digest");
export const newsCollection = collection(db, "news");
export const mailCollection = collection(db, "mail");
